import styled, {
  css,
  DefaultTheme,
  StyledComponentBase,
} from 'styled-components';

interface IDurationProps {
  isExperience?: boolean;
}

interface ContainerProps {
  wasInserted: boolean;
  priceError: boolean;
}

interface ActionsProps extends StyledComponentBase<any, DefaultTheme> {
  Validated?: any;
  BadgesContainer?: any;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  ${({ priceError }) =>
    priceError &&
    css`
      opacity: 0.7;
    `};
  background: var(--white);
  border: 1px solid var(--divider);
  padding: 20px;
  position: relative;

  .hide {
    color: white;
  }

  .btn-no-style {
    border: 0;
    background: 0;
    font-size: inherit;
    padding: 0 0.5rem;
    color: var(--primary);

    &:hover {
      font-weight: bolder;
      text-decoration: underline;
    }
  }
  .dropdown {
    width: 39px;
    height: 39px;
  }
  .dropdown-item {
    font-size: small;
  }

  .dropdown-toggle {
    background-color: white !important;
    border-color: #e7e7e7;
    color: gray;
    border-radius: 0px;
  }
  .dropdown-trigger {
    width: 100%;
    height: 100%;
  }
  button {
    width: 100%;
    height: 100%;
  }
  .dropdown-toggle::after {
    display: none !important;
  }
  .priceError {
    background: transparent !important;
  }

  > img {
    width: 100px;
    margin: auto 20px auto 0px;
  }

  > form {
    flex: 1;
    display: flex;
    align-items: center;
  }

  & + div {
    margin-top: 10px;
  }

  .flags {
    margin-top: 0 !important;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .flags .onshore {
    width: 60px;
    background-color: #f2dede;
    padding: 4px;
  }
  .flags .renewal {
    display: inline;
    width: 60px;
    background-color: #f2dede;
    padding: 4px;
  }
  .flags .online {
    width: 60px;
    background-color: #ebf8ff;
    padding: 4px;
  }

  .updating-label {
    color: var(--primary);
    margin-top: -5px;
  }

  .icon-seasons {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .max-350-width {
    width: 350px;
  }

  .top-minus-2 {
    top: -2px !important;
  }

  .max-250-width {
    width: 250px;
  }

  .text-center {
    text-align: center;
  }

  .ml-5 {
    margin-left: 5px;
  }

  .quote-config-alert {
    color: var(--light-red);
    font-size: 1.2rem;
    margin-top: 10px;

    > svg {
      margin-right: 5px;
      position: relative;
      top: 2px;
    }

    &.text-right {
      text-align: right;
    }
  }

  .period-expired-alert {
    color: red;
    font-size: 1.2rem;
    margin-top: 5px;
    margin-bottom: 10px;

    > span {
      > svg {
        margin-right: 5px;
        position: relative;
        top: 2px;
      }
    }

    &.text-right {
      text-align: right;
    }
  }
  .period-expiring-alert {
    display: flex;
    align-items: center;
    color: #000;
    font-size: 1.2rem;
    margin-top: 10px;

    > span {
      background: #fff;
    }

    > svg {
      background: #fff;
      margin-right: 5px;
      position: relative;
    }

    &.text-right {
      text-align: right;
    }
  }

  ${props =>
    props.wasInserted &&
    css`
      border: 2px solid var(--green);
    `}
`;

export const Content = styled.div`
  flex: 1;
  margin-top: 0 !important;
  margin-right: 20px;

  .list-item--campus {
    display: flex;
    align-items: center;

    > img {
      width: 21px;
      margin-right: 5px;
    }
  }

  .list-item--school {
    margin-top: 4px;
  }

  h3 {
    margin: 10px 0 5px;
  }

  .limitAge {
    background-color: #e6e6e6;
    padding: 4px;
    font-size: x-small;
    margin-right: 10px;
    vertical-align: top;
  }

  .list-item--info {
    strong {
      color: var(--gray);
    }
  }

  .form-group {
    width: 250px;
    margin-top: 10px;

    > label {
      display: flex;
      justify-content: space-between;
      font-size: 1.2rem;
      line-height: 1.6rem;
      margin-bottom: 5px;
    }
  }
`;

export const PriceCss = styled.div`
  min-width: 260px;
  margin: auto;

  > h3 {
    color: var(--primary);
    display: flex;
    align-items: center;

    > .with-discount:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    > small {
      font-size: 1.2rem;
      line-height: 1.6rem;
      margin: 0 10px;
      color: var(--gray);
      font-weight: normal;
      text-decoration: line-through;
    }

    > svg {
      color: var(--primary);
    }
  }

  > .redValue {
    color: red !important;
  }

  .discount-applied {
    color: var(--black);
  }

  .value-total {
    padding-left: 1rem;
    text-decoration: line-through;
    text-decoration-style: solid;
    color: var(--red);
  }
`;

export const Duration = styled.div<IDurationProps>`
  display: flex;
  margin-top: 10px;
  ${({ isExperience }) =>
    isExperience &&
    css`
      flex-direction: column;
    `}
  align-items: center;

  > div {
    width: 100px;
    display: flex;
    margin-right: 10px;

    > input {
      width: 100%;
    }

    svg {
      margin-right: 9px;
    }
  }
`;

export const Actions: ActionsProps = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 20px;
  min-width: 192px;

  .actions-buttons {
    display: flex;
    gap: 0.5rem;
    margin: 5px 0px;
  }
`;

Actions.Validated = styled.span`
  color: white;
  white-space: nowrap;
  border-radius: 12px;
  background: var(--green);

  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0.75rem;
`;

export const PartnerSchool = styled.div`
  color: black;
  display: flex;
  gap: 0.5rem;
  padding: 0 0.75rem;
  align-items: center;
  border-radius: 12px;
  background: var(--yellow);
`;

export const ModalBox = styled.div`
  flex: 1;
  flex-flow: row wrap;
  flex-grow: 0;
  flex-direction: row;
  display: flex;

  .title {
    font-size: 1.8rem;
    padding-bottom: 13px;
    width: 100%;

    button {
      float: right;
      border: 0;
      background: 0;
      font-size: 1.6rem;
      padding: 0 0.5rem;
      color: var(--light-gray);
      font-weight: 500;

      &:hover {
        font-weight: bolder;
      }
    }
  }

  hr {
    padding: 0;
    height: 0;
    border: 0;
    border-top: 1px solid var(--light-gray);
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }

  .modal-form {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .form-group--label {
      width: 70px;
      font-size: 1.2rem;
      padding: 20px 0px;
      font-weight: bold;
      padding-top: 30px;
    }

    .form-group--date {
      padding: 20px;
    }

    .btn-submit {
      margin: 20px 0px;
    }
  }
`;

export const AllyPlusBox = styled.div`
  display: flex;
  align-items: center;

  > p {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: -webkit-linear-gradient(right, #e50069, #eb595d, #f59d26);
    margin: 0px;
    font-weight: 700;
  }
`;
