import React, { useState } from 'react';
import Modal from 'react-modal';
import ReactDatePicker from 'react-datepicker';
import { format } from 'date-fns';
import { Container as DateContainer } from '../DatePicker/styles';
import api from '../../services/api';
import Loading from '../Loading';
import Button from '../Button';
import { ModalBox } from './styles';

interface ModalProps {
  fullname: string;
  studentId: number;
  isModalOpened: boolean;
  setIsModalOpened: (data: boolean) => void;
  callbackUpdateStudent: (newStudentData: any) => void;
}

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    border: 'none',
    width: '500px',
    overflow: 'unset',
    boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ModalChangeAge: React.FC<ModalProps> = ({
  fullname,
  studentId,
  isModalOpened,
  setIsModalOpened,
  callbackUpdateStudent,
}) => {
  const today = new Date();
  const [studentBirthdate, setStudentBirthdate] = useState(today);
  const [isSaving, setIsSaving] = useState(false);

  const closeModal = (): void => {
    setIsModalOpened(false);
  };

  const calcAge = (birthDate: string): number => {
    // using the same age calculation
    const auxToday = new Date();
    const auxBirthDate = new Date(birthDate);
    let age = auxToday.getFullYear() - auxBirthDate.getFullYear();
    const m = auxToday.getMonth() - auxBirthDate.getMonth();
    if (m < 0 || (m === 0 && auxToday.getDate() < auxBirthDate.getDate())) {
      age--;
    }
    return age;
  };

  const saveStudentAge = async (): Promise<any> => {
    setIsSaving(true);
    const timelog = format(new Date(), 'yyyy-MM-dd HH:mm:ss');

    await api.put(
      `/student/${studentId}?timelog=${timelog}&onlyBirthDate=true`,
      {
        timelog,
        id: studentId,
        birthDate: studentBirthdate,
      }
    );

    const shortBirthDate = format(studentBirthdate, 'yyyy-MM-dd');
    callbackUpdateStudent({
      birthDate: shortBirthDate,
      age: calcAge(shortBirthDate),
    });

    setIsSaving(false);
    closeModal();
  };

  return (
    <Modal
      isOpen={isModalOpened}
      onRequestClose={closeModal}
      style={modalStyles}
    >
      <ModalBox>
        <span className="title">
          Edit <b>{fullname}&apos;s</b> birthdate
          <button
            type="button"
            className="btn-no-style"
            onClick={() => closeModal()}
          >
            X
          </button>
        </span>
        <hr />
        <div className="modal-form">
          <label className="form-group--label" htmlFor="studentBirthdate">
            Birthdate:
          </label>
          <div className="form-group--date">
            <DateContainer isErrored={false}>
              <ReactDatePicker
                name="studentBirthdate"
                selected={studentBirthdate}
                dateFormat="dd/MM/yyyy"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={(date: Date) => setStudentBirthdate(date)}
                required
              />
            </DateContainer>
          </div>
          {isSaving ? (
            <Button type="submit" className="btn-blue btn-submit">
              <Loading loading={isSaving} />
            </Button>
          ) : (
            <Button
              type="submit"
              className="btn-blue btn-submit"
              onClick={() => saveStudentAge()}
            >
              Save
            </Button>
          )}
        </div>
      </ModalBox>
    </Modal>
  );
};

export default ModalChangeAge;
